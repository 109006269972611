const BaseURL = 'https://app-end.awebolivia.org/api';

const sitekey ="6LdChZwUAAAAAIh32DATM4AHCVIxuEqEygIhrYVV";
const multicurso = false;
const paidMode = false;
const userCanRegister = false;

const logos = {
  logoTopbar: 'logo_plei.png',
  logoLogin: 'logo_awe1.png',
  backgroundLogin: 'background.jpg',
  logo_inicio_courses: 'logo_awe1.png',
}
const textos = {
  textoLogoLogin:'',
  bienvenido: 'Bienvenido/a',
  tituloDashboard: "AWE",
  textoDashboard: `La Academia de Mujeres Emprendedoras (AWE) ofrece una oportunidad 
  educativa para fortalecer el espíritu empresarial femenino a través de capacitaciones 
  por medio de la plataforma DreamBuilder, elaborada por Thunderbird School of Management de 
  la Universidad Estatal de Arizona, además de brindar talleres/charlas con expertos, 
  tutoría y seguimiento personalizado, acceso a redes de emprendedores y oportunidades de crecimiento.`,
  bienvenidaRegistro: `¡Bienvenida a AWE!`,
}

const auxpiciadores = [
  {
    logo: 'embajada_logo_w.png',
    width: 154,
    height: 94
  },
  {
    logo: 'logo_departamento_estado.png',
    width: 120,
    height: 120
  },
  {
    logo: 'logo_efut.png',
    width: 159,
    height: 48
  }
]

const logosLogin = [
  {
    logo: 'embajada_logo_w.png',
    width: 120,
    height: 48
  },
  {
    logo: 'logo_departamento_estado.png',
    width: 105,
    height: 48
  },
  {
    logo: 'logo_efut.png',
    width: 153,
    height: 46
  }
]

export {
  BaseURL,
  logos,
  textos,
  auxpiciadores,
  logosLogin,
  sitekey,
  multicurso,
  paidMode,
  userCanRegister
}
