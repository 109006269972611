let colorsMain ={
  color0:'transparent',
  color1:"#ffffff",     
  color2:"#000000",
  // color secundario
  color3:"#e73148",
  color4:"#2b2c6b",
  color5:'#1c1c44',
  color6:'#1c1c44',
  color7:'#1c1c44',
  color8:'#e5e5e5',
  color9:'#2b2c6b',
  color10:'#CCCCCC',
  color11:'#333333',
  color12:'#2b2c6b',
  color13:'#e73148',   //colores diferente para 24e y m360
  color14:'rgba(255,255,255,0.5)',
  color15:'rgba(2,1,12,0.4)',
}

export {
  colorsMain
}